<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i class="el-icon-s-finance"></i>
    </div>
    <div class="logo">{{$t("route.title")}}</div>
    <div class="header-right">
      <div class="header-user-con">
        <div class="time-zone">当前时区：{{$store.state.timezone}}</div>
        <lang-select class="international right-menu-item"></lang-select>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{username}}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="loginout">{{$t("route.logout")}}</el-dropdown-item>
            <el-dropdown-item divided command="personalInfo">{{$t("route.personalInfo")}}</el-dropdown-item>
            <el-dropdown-item divided command="resetPwd">{{$t("route.resetPwd")}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog :title="$t('table.edit')" :visible.sync="updateVisible" :before-close="closeDialog">
      <el-form ref="dataForm" :model="formData" :rules="rules" label-position="left" label-width="120px">
        <el-form-item :label="$t('table.newPassword')" prop="newPassword" v-if="updateType == 2">
          <el-input v-model="formData.newPassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('table.phone')" prop="phone" v-if="updateType == 1">
          <el-input v-model="formData.phone" maxlength="11"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{$t('table.cancel')}}</el-button>
        <el-button type="primary" @click="updateInfo">{{$t('table.confirm')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import bus from "@/common/bus";
import { logout, updateInfo } from "@/api/request";
import LangSelect from '@/components/lang-select'
export default {
  components: {
    LangSelect
  },
  data() {
    return {
      collapse: false,
      fullscreen: false,
      updateVisible: false,
      updateType: 1,
      formData: {},
      rules: {
        name: [{ required: true, message: "请输入账号" }],
        newPassword: [{ required: true, message: "请输入新密码" }],
        phone: [
          {
            required: true,
            pattern: /^1[34578]\d{9}$/,
            message: "请输入电话"
          }
        ]
      }
    };
  },
  computed: {
    username() {
      let user = this.$store.state.user;
      return user ? user.name : 'user';
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        logout().then(_ => {
          this.$router.push("/login");
        });
      }
      if (command == "personalInfo") {
        this.updateVisible = true;
        this.updateType = 1;
        this.formData = Object.assign({}, this.$store.state.user);
      }
      if (command == "resetPwd") {
        this.updateVisible = true;
        this.updateType = 2;
        this.formData.id = this.$store.state.user.id;
      }
    },
    closeDialog() {
      this.updateVisible = false;
      this.formData = {};
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    updateInfo() {
      this.$refs.dataForm.validate(_ => {
        _ && updateInfo(this.formData).then(res => {
          this.$notify({
            title: this.$t("tips.success"),
            message: res.msg,
            type: "success",
            duration: 2000
          });
          this.closeDialog();
        });
      });
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    }
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  }
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 250px;
  line-height: 70px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
.time-zone {
  font-size: 12px;
  margin-right: 20px;
  color: #b8b8b8;
}
</style>
