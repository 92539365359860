<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff" unique-opened router>
      <template v-for="(item, index) in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index || (index+'')" :key="index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ $t('navbar.'+item.title) }}</span>
            </template>
            <el-menu-item v-for="(subItem,i) in item.subs" :key="i" :index="subItem.index">
              {{ $t('navbar.'+subItem.title) }}
            </el-menu-item>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index || (index+'')" :key="index">
            <i :class="item.icon"></i>
            <span slot="title">{{ $t('navbar.'+item.title) }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "@/common/bus";
import { info } from "@/api/request";
export default {
  data() {
    return {
      collapse: false,
      items: [
        {
          icon: "el-icon-setting",
          index: "dashboard",
          title: 'dashboard'
        }
      ]
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    }
  },
  created() {
    if (this.$route.path !== "/login") {
      info().then(res => {
        if (res.code === "200") {
          this.$store.state.user = res.data.user;
          this.$store.state.timezone = res.data.timezone;
          if (res.data.nav) {
            let p1 = res.data.nav.filter(e => e.type === 1);
            let p2 = res.data.nav.filter(e => e.type === 2);
            p1.forEach(e => {
              let e1 = {};
              e1.icon = e.icon;
              e1.title = e.name;
              let subs = [];
              p2.forEach(ele => {
                if (ele.parentId === e.id) {
                  let item = {};
                  item.index = ele.url;
                  item.title = ele.name;
                  subs.push(item);
                }
              });
              if (subs.length > 0) {
                e1.subs = subs;
              }
              this.items.push(e1);
            });
          }
        }
      });
    }
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on("collapse", msg => {
      this.collapse = msg;
    });
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
</style>
