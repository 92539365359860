<template>
  <div>
    <el-row :gutter="20">
      <el-col>
        <el-card shadow="hover" class="mgb20">
          <div class="user-info">
            <div class="user-info-cont">
              <div class="user-info-name">{{user.name}}</div>
            </div>
            <div class="user-info-list">
              <div>{{$t('other.lastTime')}}：
                <span>{{user.lastLoginTime}}</span>
              </div>
              <div>{{$t('other.lastIp')}}：
                <span>{{getArea(user.lastIp)}}</span>
              </div>
              <div style="display:flex">

                <el-input v-model="info" placeholder="请输入接口名称"></el-input>
                <el-button type="primary" style="width:100px" @click="handle()">接口触发</el-button>
              </div>

            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from '../../api/axios'
export default {
  name: "dashboard",
  data() {
    return {
      info: ''
    }

  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    getArea(v) {
      if (v) {
        let data = JSON.parse(v);
        return data.cname;
      }
    },

    handle() {
      const _base_url = process.env.NODE_ENV === 'development' ? '/api' : (window.location.origin + '/mapi')
      axios({
        url: _base_url + this.info,
        method: 'get',
        // params: user
      })
    }
  }
};
</script>


<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
}
.user-info > div {
  margin-right: 20px;
}
.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}
.user-info-list {
  min-width: 260px;
  display: flex;
  flex-direction: column;
}
.user-info-list div {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}
</style>
