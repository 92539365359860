(function () {
  var common = window;

  common.init = function (ww,hh) {
    common.c = document.getElementsByTagName("canvas")[0],
    common.x = c.getContext("2d"),
    common.pr = 1,
    common.w = ww,
    common.h = hh,
    common.f = 90,
    common.q = undefined,
    common.m = Math,
    common.r = 0,
    common.u = m.PI * 2,
    common.v = m.cos,
    common.z = m.random;
    common.c.width = w * pr;
    common.c.height = h * pr;
    common.x.scale(pr, pr);
    common.x.globalAlpha = 0.6;
    i();
  }


  common.i = function () {
    x.clearRect(0, 0, w, h);
    q = [{
      x: 0,
      y: h * 0.7 + f
    }, {
      x: 0,
      y: h * 0.7 - f
    }];
    while (q[1].x < w + f) d(q[0], q[1]);
  }

  common.d = function (i, j) {
    x.beginPath();
    x.moveTo(i.x, i.y);
    x.lineTo(j.x, j.y);
    var k = j.x + (z() * 2 - 0.25) * f,
      n = y(j.y);
    x.lineTo(k, n);
    x.closePath();
    r -= u / -50;
    x.fillStyle =
      "#" +
      (
        ((v(r) * 127 + 128) << 16) |
        ((v(r + u / 3) * 127 + 128) << 8) |
        (v(r + u / 3 * 2) * 127 + 128)
      ).toString(16);
    x.fill();
    q[0] = q[1];
    q[1] = {
      x: k,
      y: n
    };
  }
  common.y = function (p) {
    var t = p + (z() * 2 - 1.1) * f;
    return t > h || t < 0 ? y(p) : t;
  }
})();