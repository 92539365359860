<template>
  <div class="login-wrap" @click="changeLineArt">
    <div ref="login-canv">
      <canvas style="margin-top: 10%;"></canvas>
    </div>
    <div class="ms-title">{{$t("route.title")}}</div>
    <div class="ms-login">
      <el-form v-if="isLogin" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input v-model="ruleForm.name" :placeholder="$t('table.name')" name="username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" :placeholder="$t('table.password')" v-model="ruleForm.password" @keyup.enter.native="submitForm('ruleForm')" name="password"></el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button id="loginBtn" type="primary" @click="submitForm('ruleForm')">{{$t('route.logIn')}}</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/request";
import cookies from "js-cookie";
import "@/common/line.js";

export default {
  data: function () {
    return {
      isLogin: true,
      ruleForm: {
        name: undefined,
        password: undefined
      },
      rules: {},
      dialogVisible: false
    };
  },
  created() {
    this.$nextTick(() => {
      init(this.$refs["login-canv"].clientWidth, this.$refs["login-canv"].clientHeight);
    });
  },
  mounted() {
    this.setRules();
    this.$nextTick(() => {
      this.$refs["ruleForm"].clearValidate();
    });
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    lang() {
      this.setRules();
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
    }
  },
  methods: {
    setRules() {
      this.rules = {
        name: [{ required: true, message: this.$t("rules.name") }],
        password: [{ required: true, message: this.$t("rules.password") }]
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          login(this.ruleForm).then(res => {
            cookies.set("auth", res.data.token);
            this.$message({
              message: this.$t("route.logIn") + this.$t("tips.success"),
              type: "success"
            });
            this.$router.push("/");
          });
        } else {
          return false;
        }
      });
    },
    restForm() {
      this.ruleForm = {
        name: undefined,
        password: undefined
      };
    },
    changeLineArt() {
      i();
    }
  }
};
</script>

<style lang="less" scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.ms-title {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -230px;
  text-align: center;
  font-size: 30px;
  color: #fff;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300px;
  margin: -150px 0 0 -190px;
  padding: 40px;
  border-radius: 5px;
  background: #fff;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
}
.tip {
  text-decoration: underline;
  text-align: right;
  font-size: 14px;
  color: rgb(109, 109, 109);
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: -25px;
}

.dialog-choose {
  text-align: center;
  color: #666;
  font-size: 13px;
  transition: color 0.15s linear;
  & > span {
    padding: 10px 30px 10px 30px;
    border: 1px solid #eee;
  }
  & > span:first-child {
    margin-right: 5px;
    cursor: pointer;
  }
  & > span:last-child {
    margin-left: 5px;
    cursor: no-drop;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, SimSun, sans-serif;
    color: #606266;
    user-select: none;
  }
  & > span:first-child:hover {
    color: #409eff;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
    i {
      color: #409eff;
    }
    .icon-name {
      color: #409eff;
    }
  }
  i {
    display: block;
    font-size: 46px;
    margin-bottom: 15px;
    color: #606266;
  }
  .icon-name {
    display: inline-block;
    padding: 0 3px;
    color: #606266;
  }
}
.dialog-btn {
  display: flex;
  margin-top: 20px;
  button {
    margin: auto;
    width: 50%;
  }
}
</style>